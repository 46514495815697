import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  BrandChannelMix,
  ChannelMix,
  ChannelMixGraph,
  HotelChannelMix,
} from '@core/models';
import { ChannelMixDetailsQuery, ChannelMixQueryParam } from '@shared/models';
import { ChannelMixCompare, ChannelMixDetailedYoY, ChannelMixSingle, ChannelMonth } from '@modules/brand/models';

@Injectable({
  providedIn: 'root',
})
export class ChannelMixService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v2/channel`;
  }

  getAll(): Observable<ChannelMix[]> {
    return this.http.get<ChannelMix[]>(this.baseUrl);
  }

  getBrandChannelMix(queryParam?: ChannelMixQueryParam): Observable<BrandChannelMix[]> {
    const params = this.getHttpsParams(queryParam as ChannelMixQueryParam);

    return this.http.post<BrandChannelMix[]>(`${this.baseUrl}/brand_channelmix`, params);
  }

  getHotelsChannelMix(brandId: string, queryParam?: ChannelMixQueryParam): Observable<HotelChannelMix[]> {
    const params = this.getHttpsParams(queryParam as ChannelMixQueryParam);
    if(brandId){
      params.brand_id = brandId
    }

    return this.http.post<HotelChannelMix[]>(`${this.baseUrl}/hotels_channelmix`, params);
  }

  getHotelChannelMix(hotelId: string, queryParam?: ChannelMixQueryParam): Observable<HotelChannelMix[]> {
    const params = this.getHttpsParams(queryParam as ChannelMixQueryParam);
    if(hotelId){
      params.hotel_id = hotelId
    }

    return this.http.post<HotelChannelMix[]>(`${this.baseUrl}/hotel_channelmix`, params);
  }

  getGraph(id?: string, queryParam?: ChannelMixQueryParam): Observable<ChannelMixGraph[]> {
    const params = this.getHttpsParams(queryParam as ChannelMixQueryParam);
    if(id){
      params.id = id;
    }

    return this.http.post<ChannelMixGraph[]>(`${this.baseUrl}/hotel_channelmix_graph`, params);
  }

  getHotelDetail(hotelId: string, queryParam?: ChannelMixDetailsQuery): Observable<ChannelMonth> {
    const params = this.getHttpsParams(queryParam as ChannelMixDetailsQuery)
    if(hotelId){
      params.hotel_id = hotelId
    }

    return this.http.post<ChannelMonth>(`${this.baseUrl}/channel_mix_hotel_detailed`, params);
  }

  getHotelDetailYoY(hotelId: string, queryParam?: ChannelMixDetailsQuery): Observable<ChannelMixDetailedYoY> {
    const params = this.getHttpsParams(queryParam as ChannelMixDetailsQuery)
    if(hotelId){
      params.hotel_id = hotelId
    }

    return this.http.post<ChannelMixDetailedYoY>(`${this.baseUrl}/channel_mix_hotel_detailed_yoy`, params);
  }

  getHotelDetailNew(
    hotelId: string,
    queryParam?: ChannelMixDetailsQuery
  ): Observable<ChannelMixSingle[] | ChannelMixCompare[]> {
    const params = this.getHttpsParams(queryParam as ChannelMixDetailsQuery);
    if(hotelId){
      params.hotel_id = hotelId
    }

    return this.http.post<ChannelMixSingle[] | ChannelMixCompare[]>(`${this.baseUrl}/channel_mix_detailled_new`, params);
  }

  getHttpsParams(query: ChannelMixQueryParam | ChannelMixDetailsQuery): ChannelMixQueryParam {
    const params: any = {
      dateFrom: query?.from,
      dateTo: query?.to,
      is_compare: !!query?.isCompare
    }

    if (query?.enterprises && query.enterprises.length) {
      params.enterprises = query?.enterprises?.join(',');
    }

    if (query?.brands && query.brands.length) {
      params.brands = query?.brands?.join(',');
    }

    if (query?.hotels && query.hotels.length) {
      params.hotels = query?.hotels?.join(',');
    }

    if (query?.compDateFrom && query.compDateTo) {
      params.compDateFrom = query?.compDateFrom;
      params.compDateTo = query?.compDateTo;
    }

    if (query?.defaultMonthDate){
      params.defaultMonthDate = query.defaultMonthDate
    }

    if(query?.predefineDate){
      params.predefineDate = query.predefineDate
    }

    return params;
  }
}
