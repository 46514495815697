import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { InstagramPost, InstagramReaction } from '@core/models';
import { InstagramQuery, PageContent } from '@shared/models';
import { Platform } from '@modules/social/models/platform';

@Injectable({
  providedIn: 'root',
})
export class InstagramService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v2/instagram`;
  }

  getPostReaction(query: InstagramQuery): Observable<InstagramReaction> {
    const params = this.httpParams(query);
    return this.http.post<InstagramReaction>(`${this.baseUrl}/media_insights`, params);
  }

  getPosts(query: InstagramQuery): Observable<InstagramPost[]> {
    const params = this.httpParams(query);
    return this.http.post<InstagramPost[]>(`${this.baseUrl}/medias`, params);
  }

  private httpParams(query: InstagramQuery): {[key: string]: any} {
    const params: any = {
      dateFrom: query.from,
      dateTo: query.to,
    }

    if (query?.enterprises && query.enterprises.length) {
      params.enterprises = query?.enterprises?.join(',');
    }

    if (query?.brands && query.brands.length) {
      params.brands = query?.brands?.join(',');
    }

    if (query?.hotels && query.hotels.length) {
      params.hotels = query?.hotels?.join(',');
    }

    if(query.platformId){
      params.platformId = query.platformId
    }

    return params;
  }
}
