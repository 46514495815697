import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  PaidMediaBrand,
  PaidMediaChannel,
  PaidMediaDetail, PaidMediaDetailCompare,
  PaidMediaGraph,
  PaidMediaHotel,
  PaidMediaOverview,
  PaidMediaSource,
  PaidMediaSourceTable
} from "@core/models";
import { PageContent, PaidMediaQuery } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class PaidMediaService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v2/paid_media`;
  }

  getSources(channelId: string): Observable<PaidMediaSource[]> {
    const params = {mediaId: channelId};
    return this.http.post<PaidMediaSource[]>(`${this.baseUrl}/source_list_media`, params);
  }

  getChannels(): Observable<PageContent<PaidMediaChannel>> {
    return this.http.get<PageContent<PaidMediaChannel>>(`${this.baseUrl}/channels`);
  }

  getOverview(query: PaidMediaQuery): Observable<PageContent<PaidMediaOverview>> {
    const params = this.httpParams(query);
    return this.http.post<PageContent<PaidMediaOverview>>(`${this.baseUrl}/overview`, params);
  }

  getBrands(query: PaidMediaQuery): Observable<PaidMediaBrand[]> {
    const params = this.httpParams(query);
    return this.http.post<PaidMediaBrand[]>(`${this.baseUrl}/brand_paid_media`, params);
  }

  getHotels(brandId: string, query: PaidMediaQuery): Observable<PaidMediaHotel[]> {
    const params = this.httpParams(query);
    params['brandId'] = brandId;
    return this.http.post<PaidMediaBrand[]>(`${this.baseUrl}/hotels_paid_media`, params);
  }

  getHotelMonths(hotelId: string, query: PaidMediaQuery): Observable<PaidMediaHotel[]> {
    const params = this.httpParams(query);
    params['hotelId'] = hotelId;
    return this.http.post<PaidMediaBrand[]>(`${this.baseUrl}/hotel_paid_media`, params);
  }

  getGraph(query: PaidMediaQuery): Observable<PaidMediaGraph[]> {
    const params = this.httpParams(query);
    return this.http.post<PaidMediaGraph[]>(`${this.baseUrl}/paid_media_graph`, params);
  }

  getSourceList(query: PaidMediaQuery): Observable<PaidMediaSourceTable[]> {
    const params = this.httpParams(query);
    return this.http.post<PaidMediaSourceTable[]>(
      `${this.baseUrl}/paid_media_source_list`, params
    );
  }

  getDetails(query: PaidMediaQuery): Observable<PaidMediaDetail[]> {
    const params = this.httpParams(query);
    return this.http.post<PaidMediaDetail[]>(`${this.baseUrl}/paid_media_detailed`, params);
  }

  getDetailNew(query: PaidMediaQuery): Observable<(PaidMediaDetail | PaidMediaDetailCompare)[]> {
    const params = this.httpParams(query);
    return this.http.post<(PaidMediaDetail | PaidMediaDetailCompare)[]>(`${this.baseUrl}/paid_media_hotel_detailed_new`, params);
  }

  private httpParams(query: PaidMediaQuery): {[key: string]: any} {
    const params: any = {
      dateFrom: query.from,
      dateTo: query.to,
      mediaId: query.channelId,
      isCompare: !!query.isCompare
    }

    if (query.hotelId) {
      params['hotelId'] = query.hotelId;
    }

    if (query.enterprises && query.enterprises.length) {
      params['enterprises'] = query.enterprises.join(',');
    }

    if (query.brands && query.brands.length) {
      params['brands'] = query.brands.join(',');
    }

    if (query.hotels && query.hotels.length) {
      params['hotels'] = query.hotels.join(',');
    }

    if (query.sourceIds && query.sourceIds.length) {
      params['sourceId'] = query.sourceIds.join(',');
    }

    if (query.defaultMonthDate) {
      params['defaultMonthDate'] = `${query?.defaultMonthDate}`;
    }

    if (query?.compDateFrom && query.compDateTo) {
      params['compDateFrom'] = query?.compDateFrom ?? ''
      params['compDateTo'] = query?.compDateTo ?? '';
    }

    if(query?.predefineDate){
      params['predefineDate'] = query?.predefineDate
    }

    if (query.page) {
      params['page'] = query.page;
    }

    if (query.pageSize) {
      params['size'] = query.pageSize;
    }

    return params;
  }
}
