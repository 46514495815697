import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  GoogleAdCampaign,
  GoogleAdGraph,
  GoogleAdGroup,
  GoogleAdKeyword,
} from '@core/models';
import { GoogleAdQuery } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  private readonly baseUrl: string;
  private readonly v2BaseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/google`;
    this.v2BaseUrl = `${environment.baseUrl}/v2/google`;
  }

  getGraph(query: GoogleAdQuery): Observable<GoogleAdGraph> {
    const params = this.httpParams(query);
    return this.http.post<GoogleAdGraph>(`${this.v2BaseUrl}/graph_google_ads`, params);
  }

  getCampaigns(query: GoogleAdQuery): Observable<GoogleAdCampaign[]> {
    const params = this.httpParams(query);
    return this.http.post<GoogleAdCampaign[]>(`${this.v2BaseUrl}/campaign_google_ads`, params);
  }

  getAdGroups(campaignId: string, query: GoogleAdQuery): Observable<GoogleAdGroup[]> {
    const params = this.httpParams(query);
    if(campaignId){
      params['campaign_id'] = campaignId
    }
    return this.http.post<GoogleAdGroup[]>(`${this.v2BaseUrl}/adgroup_google_ads`, params);
  }

  getKeywords(groupId: string, query: GoogleAdQuery): Observable<GoogleAdKeyword[]> {
    const params = this.httpParams(query);
    if (groupId){
      params['adgroup_id'] = groupId
    }
    return this.http.post<GoogleAdKeyword[]>(`${this.v2BaseUrl}/keyword_google_ads`, params);
  }

  private httpParams(query: GoogleAdQuery): {[key: string]: any} {
    const params: any = {
      dateFrom: query.from,
      dateTo: query.to
    }

    if (query.hotelId) params.hotel_id = query.hotelId;

    return params;
  }
}
