import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { unionBy } from 'lodash';

import { EntityRef } from '@core/models';
import { BrandService, EnterpriseService, HotelService } from '@shared/services';
import { catchError, map, Observable, of, tap } from 'rxjs';

interface FilterData {
  enterprises: EntityRef[];
  brands: EntityRef[];
  hotels: EntityRef[];
}

const store = createStore(
  { name: 'globalProperty' },
  withProps<FilterData>({ enterprises: [], brands: [], hotels: [] })
);

@Injectable({
  providedIn: 'root',
})
export class GlobalPropertyRepository {
  private cacheBrand = '';
  private cacheHotel = '';

  enterprises$: Observable<EntityRef[]> = store.pipe(
    select((state) => state.enterprises)
  );
  brands$: Observable<EntityRef[]> = store.pipe(select((state) => state.brands));
  hotels$: Observable<EntityRef[]> = store.pipe(select((state) => state.hotels));

  constructor(
    private enterpriseService: EnterpriseService,
    private brandService: BrandService,
    private hotelService: HotelService
  ) {}

  setEnterprise = (enterprises: EntityRef[]) => {
    const inStore = store.getValue().enterprises;
    const uniqueEnterprises = enterprises.length
      ? unionBy([...inStore, ...enterprises], 'id')
      : [];
    store.update((state) => ({ ...state, enterprises: uniqueEnterprises }));
  };

  setBrands = (brands: EntityRef[]) => {
    const inStore = store.getValue().brands;
    const uniqueBrands = brands.length ? unionBy([...inStore, ...brands], 'id') : [];
    store.update((state) => ({ ...state, brands: uniqueBrands }));
  };

  setHotels = (hotels: EntityRef[]) => {
    const inStore = store.getValue().hotels;
    const uniqueHotels = hotels.length ? unionBy([...inStore, ...hotels], 'id') : [];
    store.update((state) => ({ ...state, hotels: uniqueHotels }));
  };

  fetchEnterprises = (search?: string) => {
    return this.enterpriseService.getAll({ search }).pipe(
      map((enterprises) => {
        return (enterprises.items ?? []).map((e) => ({ id: e.id, name: e.name }));
      }),
      tap((enterprises) => {
        this.setEnterprise(enterprises);
      })
    );
  };

  fetchBrands = (enterpriseIds: string[], search?: string) => {
    /*const key = `${enterpriseIds.join(',')}-${search}`;
    if (this.cacheBrand === key) {
      return this.enterprises$;
    }

    this.cacheBrand = key;*/
    return this.brandService.getAll({ enterprise: enterpriseIds, search }).pipe(
      map((brands) => (brands.items ?? []).map((b) => ({ id: b.id, name: b.name }))),
      tap((brands) => {
        this.setBrands(brands);
      })
    );
  };

  fetchHotels = (brandIds: string[], enterprises: string[], search?: string) => {
    /*const key = `${brandIds.join(',')}-${enterprises.join(',')}-${search}`;
    if (this.cacheHotel === key) {
      return this.hotels$;
    }
    this.cacheHotel = key;*/
    return this.hotelService
      .getAll({ brands: brandIds, enterprises, search, size: 100 })
      .pipe(
        map((pageContent) => pageContent.items),
        map((hotels) =>
          hotels.map((h) => ({
            id: h.id,
            name: h.name,
            code: h.code,
            isActive: h.isActive,
          }))
        ),
        tap((hotels) => {
          this.setHotels(hotels);
        }),
        catchError(() => of([])) // Optionally, handle errors
      );
  };
}
