import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { TopRefDomain, TopRefDomainBrand, TopRefDomainHotel } from '@core/models';
import { TopRefDomainQuery } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class TopRefDomainService {
  private readonly baseUrl: string;
  private readonly v2BaseUrl: string;

  constructor(private http: HttpClient) {
    // this.baseUrl = 'api/topRefDomain';
    this.baseUrl = `${environment.baseUrl}/v1/top_ref`;
    this.v2BaseUrl = `${environment.baseUrl}/v2/top_ref`;
  }

  getAll(): Observable<TopRefDomain[]> {
    return this.http.get<TopRefDomain[]>(this.baseUrl);
  }

  getBrands(query: TopRefDomainQuery): Observable<TopRefDomainBrand[]> {
    const params = this.httpParam(query);
    return this.http.post<TopRefDomainBrand[]>(`${this.v2BaseUrl}/brand_top_ref`, params);
  }

  getHotels(brandId: string, query: TopRefDomainQuery): Observable<TopRefDomainHotel[]> {
    const params = this.httpParam(query);
    params['brand_id'] = brandId;
    return this.http.post<TopRefDomainBrand[]>(`${this.v2BaseUrl}/hotels_top_ref_data`, params);
  }

  getHotel(hotelId: string, query: TopRefDomainQuery): Observable<TopRefDomainHotel[]> {
    const params = this.httpParam(query);
    params['hotel_id'] = hotelId;
    return this.http.post<TopRefDomainBrand[]>(`${this.v2BaseUrl}/hotel_top_ref_data`, params);
  }

  private httpParam(query: TopRefDomainQuery): {[key: string]: any} {
    const params: any = {
      dateFrom: query.from,
      dateTo: query.to,
      is_compare: !!query.isCompare
    }

    if (query.enterprises && query.enterprises.length) {
      params['enterprises'] = query.enterprises.join(',');
    }

    if (query.brands && query.brands.length) {
      params['brands'] = query.brands.join(',');
    }

    if (query.hotels && query.hotels.length) {
      params['hotels'] = query.hotels.join(',');
    }

    if (query.defaultMonthDate) {
      params['defaultMonthDate'] = query?.defaultMonthDate;
    }

    if (query?.compDateFrom && query.compDateTo) {
      params['compDateFrom'] = query?.compDateFrom ?? ''
      params['compDateTo'] = query?.compDateTo ?? '';
    }

    if (query.predefineDate){
      params['predefineDate'] = query.predefineDate
    }

    return params;
  }
}
