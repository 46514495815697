import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { environment } from '@env/environment';
import { Brand } from '@core/models';
import { BrandQueryParam, PageContent } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  private readonly baseUrl: string;
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/brand/global`;
  }

  getAll(param: BrandQueryParam): Observable<PageContent<Brand>> {
    // Ennable the loading state.
    this.loading$.next(true)
    const body = {
      page: param.page ?? 1,
      size: param.size ?? 50,
      search: param.search ?? '',
      orderBy: param.orderBy ?? '',
      enterprises: (param.enterprise ?? []).join(',')
    }

    return this.http.post<PageContent<Brand>>(this.baseUrl, body).pipe(tap(() => {
      this.loading$.next(false)
    }));
  }

  create(brand: Brand): Observable<Brand> {
    return this.http.post<Brand>(this.baseUrl, brand);
  }

  update(brand: Brand): Observable<Brand> {
    return this.http.put<Brand>(`${this.baseUrl}/${brand.id}`, brand);
  }

  delete(brand: Brand): Observable<unknown> {
    return this.http.delete<unknown>(`${this.baseUrl}/${brand.id}`);
  }
}
