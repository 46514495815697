import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  VisitRevenueBrand,
  VisitRevenueCompareDetail,
  VisitRevenueDetail,
  VisitRevenueGraph,
  VisitRevenueHotel,
  VisitsRevenueDetails,
} from '@core/models';
import { VisitQueryParam } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class VisitRevenueService {
  private readonly baseUrl: string;
  private readonly v2BaseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/visits`;
    this.v2BaseUrl = `${environment.baseUrl}/v2/visits`;
  }

  getBrands(query: VisitQueryParam): Observable<VisitRevenueBrand[]> {
    const params = this.httpParam(query);
    return this.http.post<VisitRevenueBrand[]>(`${this.v2BaseUrl}/brand_visits_rev`, params);
  }

  getHotels(brandId: string, query: VisitQueryParam): Observable<VisitRevenueHotel[]> {
    const params = this.httpParam(query);
    params['brand_id'] = brandId;
    return this.http.post<VisitRevenueHotel[]>(`${this.v2BaseUrl}/hotels_visits_rev`, params);
  }

  getHotel(hotelId: string, query: VisitQueryParam): Observable<VisitRevenueHotel[]> {
    const params = this.httpParam(query);
    if (!('hotel_id' in params)) params['hotel_id'] = hotelId;

    return this.http.post<VisitRevenueHotel[]>(`${this.v2BaseUrl}/hotel_visits_rev`, params);
  }

  getGraph(query: VisitQueryParam): Observable<VisitRevenueGraph[]> {
    const params = this.httpParam(query);
    return this.http.post<VisitRevenueGraph[]>(`${this.v2BaseUrl}/visits_rev_graph`, params);
  }

  getDetails(query: VisitQueryParam): Observable<VisitsRevenueDetails[]> {
    const params = this.httpParam(query);
    return this.http.post<VisitsRevenueDetails[]>(`${this.v2BaseUrl}/visits_rev_detailed`, params);
  }

  getDetailsNew(
    hotelId: string,
    queryParam: VisitQueryParam
  ): Observable<VisitRevenueDetail | VisitRevenueCompareDetail[]> {
    const params = this.httpParam({ ...queryParam, hotelId });
    return this.http.post<VisitRevenueDetail | VisitRevenueCompareDetail[]>(
      `${this.v2BaseUrl}/visits_rev_hotel_detailed_new`, params
    );
  }

  private httpParam(query: VisitQueryParam): {[key: string]: any} {
    const params: any = {
      dateFrom: query.from,
      dateTo: query.to,
      is_compare: !!query?.isCompare
    }

    if (query.hotelId) {
      params['hotel_id'] = query.hotelId;
    }

    if (query.enterprises && query.enterprises.length) {
      params['enterprises'] = query.enterprises.join(',');
    }

    if (query.brands && query.brands.length) {
      params['brands'] = query.brands.join(',');
    }

    if (query.hotels && query.hotels.length) {
      params['hotels'] = query.hotels.join(',');
    }

    if (query.defaultMonthDate) {
      params['defaultMonthDate'] = query?.defaultMonthDate;
    }

    if (query?.compDateFrom && query.compDateTo) {
      params['compDateFrom'] = query?.compDateFrom ?? ''
      params['compDateTo'] = query?.compDateTo ?? '';
    }

    if(query.predefineDate){
      params['predefineDate'] = query?.predefineDate
    }

    return params;
  }
}
