import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import {
  SourceItems,
  SourceTrafficBrand,
  SourceTrafficGraph,
  SourceTrafficHotel,
  SourceTrafficLegacy,
} from '@core/models';
import { SourceQueryParam } from '@shared/models/source-query-param';

@Injectable({
  providedIn: 'root',
})
export class SourceTrafficService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v2/source`;
  }

  getAll(): Observable<SourceTrafficLegacy[]> {
    return this.http.get<SourceTrafficLegacy[]>(this.baseUrl);
  }

  getBrands(query: SourceQueryParam): Observable<SourceTrafficBrand[]> {
    const params = this.httpParams(query);
    return this.http.post<SourceTrafficBrand[]>(`${this.baseUrl}/brand_source_traffic`, params);
  }

  getHotels(brandId: string, query: SourceQueryParam): Observable<SourceTrafficHotel[]> {
    const params = this.httpParams(query);
    params['brand_id'] = brandId;
    return this.http.post<SourceTrafficHotel[]>(`${this.baseUrl}/hotels_source_traffic`, params);
  }

  getHotel(hotelId: string, query: SourceQueryParam): Observable<SourceTrafficHotel[]> {
    const params = this.httpParams(query);
    params['hotel_id'] = hotelId;
    return this.http.post<SourceTrafficHotel[]>(`${this.baseUrl}/hotel_source_traffic`, params);
  }

  getGraph(query: SourceQueryParam): Observable<SourceTrafficGraph[]> {
    const params = this.httpParams(query);
    return this.http.post<SourceTrafficGraph[]>(`${this.baseUrl}/source_traffic_graph`, params);
  }

  getSources(): Observable<SourceItems> {
    return this.http.get<SourceItems>(`${this.baseUrl}`);
  }

  private httpParams(query: SourceQueryParam): {[key: string]: any} {
    const params: any = {
      dateFrom: query.from,
      dateTo: query.to,
      is_compare: !!query.isCompare
    }

    if (query.enterprises && query.enterprises.length) {
      params['enterprises'] = query.enterprises.join(',');
    }

    if (query.brands && query.brands.length) {
      params['brands'] = query.brands.join(',');
    }

    if (query.hotels && query.hotels.length) {
      params['hotels'] = query.hotels.join(',');
    }

    if (query.defaultMonthDate) {
      params['defaultMonthDate'] = query?.defaultMonthDate;
    }

    if (query?.compDateFrom && query.compDateTo) {
      params['compDateFrom'] = query?.compDateFrom ?? ''
      params['compDateTo'] = query?.compDateTo ?? '';
    }

    if (query?.sourceId && query?.sourceId.length) {
      params['sourceId'] = query?.sourceId.join(',');
    }
    
    if (query.predefineDate){
      params['predefineDate'] = query.predefineDate
    }

    return params;
  }
}
